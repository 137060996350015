/*! Shared-App-PopupModal ~ (c) 2012-2015 Flyp Technologies Inc. ~ http://uberflip.com */
/*
 * Shared Component - Popup-Modal
 *
 * @options:
 *
 *      'type'        : string,                 // alert, confirm, content  (REQUIRED; No Default)
 *      'method'      : string,                 // text, ajax, iframe       (Default text)
 *      'modal'       : string|boolean,         // false or string: Element ID containing content
 *      'header'      : string|boolean|object,  // false or string or object: {'text':string, 'img':string, 'desc':string}
 *      'footer'      : string|boolean,         // false or string
 *      'content'     : string,                 // string or jQuery Element Handle
 *      'closable'    : boolean,                // (Default true)
 *      'autoResize'  : boolean,                // (Default true)
 *      'ajaxSpinner' : boolean,                // only valid when loading content via AJAX
 *      'width'       : 0,
 *      'height'      : 0,
 *      'padding'     : 0,
 *      'align'       : 'left',
 *      'buttons'     : [],                     //  [{'label':string, 'classes':string, 'align':string, 'handler':function(e)}, ...]
 *
 *      'filters'     : {},                     //  {'filter-key': {'label':string, 'aria':string, 'active':boolean}, ...}
 *
 *      'wizard'      : {}                      //  {
 *                                              //      'labels'   : {'next':string, 'previous':string, 'finish':string},
 *                                              //      'steps'    : [ {'key':string, 'label':string, 'aria':string, 'onStep':function()}, ... ]
 *                                              //  }
 *
 *      'onShown'         : null,               // onLoad callback for non-ajax content modal
 *      'onContentRender' : null,
 *      'onAjaxLoad'  : function() { return true; }, // return true to show footer after load, false otherwise
 *      'className'   : ''
 *
 * @usage:
 *
 *   Alert:
 *   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *     PopupModal2.alert('my alert message');
 *     alert('my alert message');
 *     alert('my alert message', {
 *         'ok'     : 'OK',             // Any Text for Ok Button
 *         'header' : 'Alert!',         // Any Text for Header Label
 *         'align'  : 'center',         // left, right, center or justify
 *         'width'  : 560,              // Width of Modal in Pixels
 *         'height' : 520               // Max Height of Modal in Pixels
 *     });
 *     alert('my alert message').then(function promiseResolved() {
 *         // Callback when Alert is Closed
 *     });
 *
 *   Confirm:
 *   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *     PopupModal2.confirm('Do You Confirm?');
 *     PopupModal2.confirm('Do You Confirm?', {
 *         'ok'     : 'OK',             // Any Text for Ok Button
 *         'cancel' : 'Cancel',         // Any Text for Cancel Button
 *         'header' : 'Confirm?',       // Any Text for Header Label
 *         'align'  : 'center',         // left, right, center or justify
 *         'width'  : 560,              // Width of Modal in Pixels
 *         'height' : 520               // Max Height of Modal in Pixels
 *     });
 *     PopupModal2.confirm('Do You Confirm?').then(
 *          function promiseResolved() {
 *              // Callback when OK is Clicked
 *          },
 *          function promiseRejected() {
 *              // Callback when Cancel is Clicked
 *          }
 *     );
 *
 *   Content/Show:
 *   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *     PopupModal2.show('This is My Content Popup!');
 *     PopupModal2.content('This is My Content Popup!');
 *     PopupModal2.content('This is My Content Popup!', {
 *         'header'     : 'My Content Popup Modal',         // Any Text for Header Label
 *         'align'      : 'left',                           // left, right, center or justify
 *         'closable'   : true,                             // True if the Modal should be closable (Close Icon, Backdrop) or False if it requires a Footer Button Click to close
 *         'disposable' : true,                             // True if the Modal can be disposed of after it is closed
 *         'width'      : 560,                              // Width of Modal in Pixels
 *         'height'     : 520,                              // Max Height of Modal in Pixels
 *         'padding'    : 2                                 // Padding of the Modal Content Element
 *     });
 *     PopupModal2.content('This is My Content Popup!', {
 *         'modal'  : '#myModalElement',    // If the Modal Template HTML is already on the Page, provide the Container Element ID to use the Existing Modal Template (otherwise omit)
 *         'width'  : 560,
 *         'height' : 520
 *     });
 *     PopupModal2.content('http://www.uberflip.com', {
 *         'method' : 'ajax'                // Load URL via ajax or as an iframe (default is ajax)
 *     });
 *     PopupModal2.content('This is My Content Popup!').then(
 *          function promiseResolved() {
 *              // Callback when Primary Button is Clicked
 *          },
 *          function promiseRejected() {
 *              // Callback when Secondary Button is Clicked or the Modal is Closed via Close-Icon or Backdrop
 *          }
 *     );
 */

import { getUUID, onNextEventLoop } from '../util';
import $ from 'jquery';
import _ from 'lodash';
import Q from 'q';

// View Templates
const modalTemplate =
  '<div class="modal fade modal-v2" tabindex="-1" role="dialog"> \
        <div class="modal-dialog" role="document"> \
            <div class="modal-content"> \
                <div class="modal-header"> \
                    <button id="modal-v2-header-close" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> \
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" class="modal-image"/> \
                    <div class="modal-sprite"></div> \
                    <h4 class="modal-title"></h4> \
                    <p class="modal-desc"></p> \
                    <div class="modal-nav" role="tablist"> \
                        <ul class="nav nav-pills"></ul> \
                    </div> \
                </div> \
                <div class="modal-body"></div> \
                <div class="modal-footer"></div> \
                <div class="modal-loading"> \
                    <span class="loading-text"></span> \
                    <span class="loading-subtext"></span> \
                </div> \
            </div> \
        </div> \
    </div> ';

/**
 * PopupModal - Singleton Class
 *
 * Exposed Methods:
 *   - getModal
 *   - alert
 *   - confirm
 *   - content
 *   - reload
 *   - hide
 *
 * @class PopupModal
 * @description Customized Popup Modal Wrapper around the Bootstrap Modal Conponent
 * @returns {object} - The Class Interface
 */
const PopupModal2 = (function () {
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Internal Private Vars
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  let instance = null; // Singleton Instance
  let modalId = '';
  let modalOptions = {};
  let $modal = null;
  let $modalHeader = null;
  let $modalHeaderText = null;
  let $modalHeaderImg = null;
  let $modalHeaderSprite = null;
  let $modalHeaderDesc = null;
  let $modalHeaderTabs = null;
  let $modalContent = null;
  let $modalFooter = null;
  let $modalLoading = null;
  let $closeIcon = null;
  let $altCloseIcon = null;
  const deferred = { hide: null, modal: null, resolveValue: null };
  let isClosable = false;
  let isShown = false;
  let isLoading = false;
  let isDisposable = false;
  const wizard = { currentStep: 0, totalSteps: 0 };

  const classNameEventListeners = {};

  // HTML Templates (too small to put into an external template file)
  const buttonTpl = '<button type="button" class="btn btn-default"></button>';
  const filterLinkTpl = '<li role="presentation"><a role="tab" data-toggle="tab"></a></li>';
  const closeIconTpl =
    '<button id="modal-v2-fallback-close" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
  const glyphIconTpl = '<span class="halflings halflings-{{glyph}}" aria-hidden="true"></span>';

  // PopupModal Public Interface; Exported Methods made available via PopupModal2.*
  const exports = {
    addEventListener(className, eventKey, callback) {
      if (!(className in classNameEventListeners)) {
        classNameEventListeners[className] = {};
      }

      const eventListeners = classNameEventListeners[className];

      let hasSameEventListener = false;
      if (eventKey in eventListeners) {
        hasSameEventListener = eventListeners[eventKey].some((l) => Object.is(l, callback));
      } else {
        eventListeners[eventKey] = [];
      }

      if (!hasSameEventListener) {
        // merge or create listeners for eventKey
        eventListeners[eventKey].push(callback);

        // if modal already built add to existing modal
        const $modal = getInstance().getModal();

        if ($modal && $modal.hasClass(className)) {
          $modal.on(eventKey, callback);
        }
      }
    },
    alert(message, options) {
      return getInstance().alert(message, options);
    },
    confirm(message, options) {
      return getInstance().confirm(message, options);
    },
    content(content, options) {
      return getInstance().content(content, options);
    },
    getBody() {
      return getModal().find('.modal-body');
    },
    getInstance() {
      return getInstance();
    },
    getModal() {
      return getInstance().getModal();
    },
    hide(resolveValue) {
      return getInstance().hide(resolveValue);
    },

    // Alias of content:
    show(content, options) {
      return getInstance().content(content, options);
    },

    // API For Step Modals
    // eslint-disable-next-line sort-keys
    disableFinishStep() {
      return getInstance().disableFinishStep();
    },
    disableNextStep() {
      return getInstance().disableNextStep();
    },
    disablePreviousStep() {
      return getInstance().disablePreviousStep();
    },
    enableFinishStep() {
      return getInstance().enableFinishStep();
    },
    enableNextStep() {
      return getInstance().enableNextStep();
    },
    enablePreviousStep() {
      return getInstance().enablePreviousStep();
    },
    nextStep() {
      return getInstance().nextStep();
    },
    previousStep() {
      return getInstance().previousStep();
    },

    // For Reloading Content from AJAX
    reload(content) {
      return getInstance().reload(content);
    },
    removeEventListener(className, eventKey, callback) {
      if (className in classNameEventListeners) {
        const eventListeners = classNameEventListeners[className];

        if (eventKey in eventListeners) {
          const listeners = eventListeners[eventKey];
          const listenerCallbackIndex = listeners.indexOf(callback);

          if (listenerCallbackIndex !== -1) {
            // listeners will only have one matching callback
            listeners.splice(listenerCallbackIndex, 1);

            // if modal already built remove from existing modal
            const $modal = getInstance().getModal();

            if ($modal && $modal.hasClass(className)) {
              $modal.off(eventKey, callback);
            }
          }
        }
      }
    },

    // For Loading Screen
    updateLoadingText() {
      return getInstance().updateLoadingText();
    },
  };

  /**
   * Initializes the PopupModal Object
   *
   * @private
   * @returns {object} - An Interface Object with Exposed Public Methods
   * @constructs
   */
  function init() {
    // PopupModal Singleton Interface
    return {
      addEventListener,

      alert: showAlert,
      confirm: showConfirm,
      content: showContent,
      getModal,
      hide,
      reload,

      // API For Step Modals:
      // eslint-disable-next-line sort-keys
      disableFinishStep,
      disableNextStep,
      disablePreviousStep,
      enableFinishStep,
      enableNextStep,
      enablePreviousStep,
      nextStep,
      previousStep,

      removeEventListener,

      // For Loading Screen
      updateLoadingText,
    };
  }

  /**
   * Gets a Singleton Reference to the PopupModal Object
   *
   * @private
   * @returns {object} - A Singleton Reference to the PopupModal Object
   */
  function getInstance() {
    if (!instance) {
      instance = init();
    }
    return instance;
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Internal Private Methods
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  /**
   * Shows the Modal and Returns a Promise that gets Resolved/Rejected when the Modal is Closed
   *  - If another Modal is already open, Close Previous Modal if it is Closable, otherwise Shake Modal to draw User Attention
   *
   * @private
   * @returns {Promise.<mixed>} - The Resolve Value if the Modal was Resolved when Closed, otherwise the Rejection Value if the Modal was Rejected when Closed
   */
  function show() {
    const showModal = function PopupModal_show_showModal() {
      // Prepare New Modal
      prepare();

      // Hook 'shown' event for onShown event
      $modal.on('shown.bs.modal', shown);

      // Resolve Promise after Hide
      $modal.on('hidden.bs.modal', hidden);

      // Remove Transition on Request
      if (!modalOptions.useFade) {
        $modal.removeClass('fade');
      } else if (!$modal.hasClass('fade')) {
        $modal.addClass('fade');
      }

      // Show Modal
      $modal.modal('show');
      isShown = true;
    };

    // Reset resolveValue
    deferred.resolveValue = null;

    // Create Promise Object
    deferred.modal = Q.defer();

    // Hide Previous Modal
    if (isShown) {
      if (isClosable) {
        hide(false).then(showModal);
      } else {
        shake();
      }
    } else {
      showModal();
    }

    return deferred.modal.promise;
  }

  /**
   * Hides the Modal and Resolves or Rejects the Promise
   *
   * @private
   * @param {*} resolveValue - True if OK was clicked, False if Cancel was clicked
   * @returns {Promise.<mixed>} - The Resolve Value if the Modal was Resolved when Closed, otherwise the Rejection Value if the Modal was Rejected when Closed
   */
  function hide(resolveValue) {
    if (!isShown) {
      return Q('not-shown');
    }

    deferred.hide = Q.defer();
    deferred.resolveValue = resolveValue;

    // Hide Modal
    onNextEventLoop(() => {
      $modal.modal('hide');
    });

    return deferred.hide.promise;
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function shown() {
    // onLoad Callback
    if (_.isFunction(modalOptions.onShown)) {
      modalOptions.onShown();
    }
  }

  /**
   * Hides the Modal and Resolves or Rejects the Promise
   *
   * @private
   * @param {boolean} resolveValue - True if OK was clicked, False if Cancel was clicked
   * @returns {undefined}
   */
  function hidden() {
    // Detach Events
    $modal.off('hidden.bs.modal').off('shown.bs.modal');

    // Remove Alt Close Icon (if exists)
    if ($altCloseIcon !== null) {
      $altCloseIcon.remove();
      $altCloseIcon = null;
    }

    // Remove Modal if Disposable
    if (isDisposable) {
      $modal.remove();
    }
    $modal = null;
    isShown = false;
    instance = null;

    // Resolve Promise
    if (deferred.resolveValue) {
      deferred.modal.resolve('ok');
    } else {
      deferred.modal.reject('cancel');
    }

    // Resolve Hide Promise (if exists)
    //  - Modal may have been closed by clicking on Backdrop, which doesn't call hide()
    if (deferred.hide !== null) {
      deferred.hide.resolve('hidden');
      deferred.hide = null;
    }
  }

  /**
   * Prepares the Popup Modal by Showing/Hiding and Populating the Modal Elements (Header, Footer, Body, Buttons)
   *
   * @private
   * @returns {undefined}
   */
  function prepare() {
    // Build Modal
    build();
    checkClosable();
    updateHeader();
    updateFooter();
    updateSettings();
    const remoteContentUrl = updateContent();

    // Attach Bootstrap Modal Functionality
    $modal.modal({ show: false });
    updateBootstrapModalOptions();

    // saving the options for later use
    PopupModal2.PreviousOptions = modalOptions;

    // Load Content via AJAX
    if (remoteContentUrl && remoteContentUrl.length) {
      /* eslint-disable sort-keys */
      Q.invoke($, 'ajax', { url: remoteContentUrl, cache: false }).then(loaded).fail(failedLoad);
      /* eslint-enable sort-keys */
    } else {
      updateAfterContentRendered();
    }
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function build(modalContainer) {
    if (modalContainer === undefined) {
      // eslint-disable-next-line no-param-reassign
      modalContainer = 'body';
    }

    // Build Modal from Template
    $modal = $(modalTemplate).appendTo(modalContainer);

    // Give Modal an ID
    modalId = `modal-${getUUID()}`;
    $modal.attr('id', modalId);

    // Add CSS Class to Modal
    if (modalOptions.className !== undefined && modalOptions.className.length) {
      $modal.addClass(modalOptions.className);
    }

    // Get Modal Elements
    getModalElements();

    attachListeners();
  }

  /**
   *
   * @private
   * @returns {undefined}
   */
  function getModalElements() {
    // Primary Elements
    $modalHeader = $modal.find('.modal-header');
    $modalContent = $modal.find('.modal-body');
    $modalFooter = $modal.find('.modal-footer');
    $modalLoading = $modal.find('.modal-loading');

    // Header Elements
    $modalHeaderText = $modalHeader.find('.modal-title');
    $modalHeaderImg = $modalHeader.find('.modal-image');
    $modalHeaderSprite = $modalHeader.find('.modal-sprite');
    $modalHeaderDesc = $modalHeader.find('.modal-desc');
    $modalHeaderTabs = $modalHeader.find('.modal-nav');
    $closeIcon = $modalHeader.find('.close');

    // Hide Header/Footer Elements by Default
    $modalHeader.css({ display: 'none' });
    $modalFooter.css({ display: 'none' });
    $modalLoading.css({ display: 'none' });
    $closeIcon.css({ display: 'none' });
  }

  /**
   * attaches event listeners to the modal
   * @private
   * @returns {undefined}
   */
  function attachListeners() {
    for (const className in classNameEventListeners) {
      if (!$modal.hasClass(className)) continue;

      const eventListeners = classNameEventListeners[className];

      for (const key in eventListeners) {
        const listeners = eventListeners[key];

        listeners.forEach((l) => {
          $modal.on(key, l);
        });
      }
    }
  }

  function checkClosable(data) {
    // Determine Modal Functionality
    isClosable = true;
    if (modalOptions.closable !== undefined) {
      isClosable = modalOptions.closable;
    }
    isDisposable = true;
    if (modalOptions.disposable !== undefined) {
      isDisposable = modalOptions.disposable;
    }

    if (data !== undefined && _.isPlainObject(data)) {
      if (data.closable !== undefined) {
        isClosable = modalOptions.closable = data.closable;
      }
      if (data.disposable !== undefined) {
        isDisposable = modalOptions.disposable = data.disposable;
      }
    }
  }

  /**
   * Shows the footer once the remote content has been loaded
   *
   * @private
   * @param {object|string} data
   * @returns {undefined}
   */
  function loaded(data) {
    let showFooter = true;

    // Hide Loading
    updateLoading(false);

    // Handle Response as String of Content
    if (!_.isPlainObject(data)) {
      // check if data already has a modal body
      if (data.indexOf('modal-body') > -1) {
        $modalContent.after(data);
        $modalContent.remove();
        $modalContent = $modal.find('.modal-body');
      } else {
        // Load Content into Modal
        $modalContent.html(data);
      }

      // Handle Response as JSON Object
    } else {
      // eslint-disable-next-line no-param-reassign
      data = data.response;

      checkClosable(data);
      updateBootstrapModalOptions();

      // Update Header Text
      if (data.headerText !== undefined) {
        $modalHeaderText.html(data.headerText);
      }

      // Update Header Text/Desc/Img
      if (data.header !== undefined) {
        // Add the new header
        modalOptions.header = data.header;
        updateHeader();
      }

      // Update Footer Text and/or Footer Buttons
      if (data.footer !== undefined || data.buttons !== undefined) {
        // Remove existing footer/buttons
        $modalFooter.empty();

        // Add the new footer/buttons
        if (data.footer !== undefined) {
          modalOptions.footer = data.footer;
        }
        if (data.buttons !== undefined) {
          modalOptions.buttons = data.buttons;
        }
        updateFooter();
      }

      // Update Filters
      if (data.filters !== undefined) {
        // Add the new filters
        modalOptions.filters = data.filters;
      }

      // Update Wizard
      if (data.wizard !== undefined) {
        // Add the new wizard
        modalOptions.wizard = data.wizard;
      }

      // Load Content into Modal
      $modalContent.html(data.content);
    }

    // Update Modal
    updateAfterContentRendered();

    // On Load Callback
    if (_.isFunction(modalOptions.onAjaxLoad)) {
      showFooter = modalOptions.onAjaxLoad();
    }

    // Reveal Footer
    if (hasFooter() && showFooter) {
      $modalFooter.css({ visibility: 'visible' }).animate({ opacity: 1 }, 200);
    }
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function failedLoad(errorResponse) {
    let showFooter = false;

    // Hide Loading
    updateLoading(false);

    if (errorResponse.responseJSON !== undefined) {
      // eslint-disable-next-line no-param-reassign
      errorResponse = errorResponse.responseJSON;
    }

    // On Fail Callback
    if (_.isFunction(modalOptions.onAjaxFail)) {
      showFooter = modalOptions.onAjaxFail(errorResponse);
    }

    // Reveal Footer
    if (hasFooter() && showFooter) {
      $modalFooter.css({ visibility: 'visible' }).animate({ opacity: 1 }, 200);
    }
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateHeader() {
    let largeHeader = false;
    if (!hasHeader()) {
      return;
    }

    // Hide Unused Elements by Default
    $modalHeaderImg.css({ display: 'none' });
    $modalHeaderSprite.css({ display: 'none' });
    $modalHeaderDesc.css({ display: 'none' });

    // If Object, properties are: {text: '', img: '', desc: ''}
    if (_.isPlainObject(modalOptions.header)) {
      // Set Header Title (H4)
      if (modalOptions.header.text !== undefined && modalOptions.header.text.length) {
        $modalHeaderText.empty().html(modalOptions.header.text);
      }

      // Set Header Image (if exists)
      if (modalOptions.header.img !== undefined && modalOptions.header.img.length) {
        // Append Base IMG URL
        if (!/^http|^\/\//i.test(modalOptions.header.img)) {
          modalOptions.header.img = `${AppGlobals.imgUrl}img/${modalOptions.header.img}?v=${AppGlobals.imgVersion}`;
        }
        $modalHeaderImg.attr('src', modalOptions.header.img).css({ display: 'block' });
        largeHeader = true;
      }

      // Set Header Sprite (if exists)
      if (modalOptions.header.sprite !== undefined && modalOptions.header.sprite.length) {
        $modalHeaderSprite.html(modalOptions.header.sprite).css({ display: 'block' });
        largeHeader = true;
      }

      // Set Header Description (if exists)
      if (modalOptions.header.desc !== undefined && modalOptions.header.desc.length) {
        $modalHeaderDesc.empty().html(modalOptions.header.desc).css({ display: 'block' });
        largeHeader = true;
      }
    } else {
      // Header as String
      $modalHeaderText.empty().html(modalOptions.header);
    }

    // Apply Large Header Style
    if (largeHeader) {
      $modalHeader.addClass('large-header');
    }

    // Display Header
    $modalHeader.css({ display: 'block' });
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateFilters() {
    let activeKey = '';
    const $ul = $modalHeaderTabs.find('ul.nav');

    // Reset Filters Nav
    if (!hasWizardLayout()) {
      $modalHeaderTabs.css({ display: 'none' });
    }
    if (!hasFilterLayout() || !$ul.length) {
      return;
    }
    $ul.empty();

    // Build Links for Filters Nav (Tabs for Nav should be within Content)
    _.forEach(modalOptions.filters, (filterData, filterKey) => {
      // Create Filter Link
      const $li = $(filterLinkTpl);
      const $link = $li.find('a').html(filterData.label);
      $link.on('click.PopupModal', handleFilterTab(filterKey));

      // Mark LI tag
      $li.attr('data-filter-key', filterKey);

      // Add Accessibility Attributes
      if (filterData.aria.length) {
        $link.attr('aria-controls', filterData.aria);
      }

      // Append Link to Filters Nav
      $ul.append($li);

      // Check for Active Key
      if (filterData.active) {
        activeKey = filterKey;
      }
    });

    // Check for Valid Active Filter, or set first Filter as Active
    if (!activeKey || !activeKey.length) {
      activeKey = _.keys(modalOptions.filters)[0];
    }

    // Toggle the Active Filter
    toggleActiveFilter(activeKey);

    // Show Filters Nav
    $modalHeaderTabs.css({ display: 'block' });
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateWizardSteps() {
    let $link;
    let $li;
    const $ul = $modalHeaderTabs.find('ul.nav');

    // Reset Wizard Nav
    if (!hasFilterLayout()) {
      $modalHeaderTabs.css({ display: 'none' });
    }
    if (!hasWizardLayout() || !$ul.length) {
      return;
    }
    $ul.empty();

    // Reset Current Step
    wizard.currentStep = 0;
    wizard.totalSteps = modalOptions.wizard.steps.length;

    // Build Links for Steps Nav (Tabs for Nav should be within Content)
    for (let i = 0; i < wizard.totalSteps; i++) {
      // Create Step Link
      $li = $(filterLinkTpl);
      $link = $li.find('a').html(modalOptions.wizard.steps[i].label);
      $link.on('click.PopupModal', handleStepTab(i));

      // Mark LI tag (same as filters because it uses the same toggle function)
      $li.attr('data-filter-key', modalOptions.wizard.steps[i].key);

      // Add Accessibility Attributes
      if (modalOptions.wizard.steps[i].aria.length) {
        $link.attr('aria-controls', modalOptions.wizard.steps[i].aria);
      }

      // Append Link to Steps Nav
      $ul.append($li);

      // Disable Future Steps
      if (i > wizard.currentStep) {
        $link.addClass('disabled');
      }
    }

    // Toggle the Active Step (First Step)
    toggleActiveFilter(modalOptions.wizard.steps[wizard.currentStep].key);

    // Add CSS Classes for Step Layout
    $modal.addClass('has-step-nav');
    $ul.addClass('nav-justified');

    // Show Filters Nav
    $modalHeaderTabs.css({ display: 'block' });

    // Call Step Function for Custom Logic
    if (_.isFunction(modalOptions.wizard.steps[wizard.currentStep].onStep)) {
      onNextEventLoop(modalOptions.wizard.steps[wizard.currentStep].onStep);
    }
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateFooter() {
    let $button = null;
    let glyphicon = '';

    if (!hasFooter()) {
      $modalFooter.css({ display: 'none' });
      return;
    }

    // Display Footer Containers
    $modalFooter.empty().css({ display: 'block' });

    // Add Custom Buttons to Button Container
    if (modalOptions.buttons !== undefined && modalOptions.buttons.length) {
      // Iterate All Custom Buttons
      for (let i = 0, n = modalOptions.buttons.length; i < n; i++) {
        // Create Custom Button
        $button = $(buttonTpl)
          .addClass(modalOptions.buttons[i].classes)
          .html(modalOptions.buttons[i].label);
        $button.on('click.PopupModal', handleCustom(modalOptions.buttons[i]));

        if (modalOptions.buttons[i].disabled !== undefined) {
          $button.prop('disabled', modalOptions.buttons[i].disabled);
        }

        // Button Alignment
        if (modalOptions.buttons[i].align === undefined || !modalOptions.buttons[i].align.length) {
          modalOptions.buttons[i].align = 'right';
        }
        if (/center/i.test(modalOptions.buttons[i].align)) {
          $button.addClass('center-block');
        } else {
          $button.addClass(`pull-${modalOptions.buttons[i].align.toLowerCase()}`);
        }

        // Button Glyph Icon
        if (modalOptions.buttons[i].glyph !== undefined && modalOptions.buttons[i].glyph.length) {
          glyphicon = glyphIconTpl.replace('{{glyph}}', modalOptions.buttons[i].glyph);

          if (
            modalOptions.buttons[i].glyphpos === undefined ||
            !modalOptions.buttons[i].glyphpos.length
          ) {
            modalOptions.buttons[i].glyphpos = 'left';
          }
          if (modalOptions.buttons[i].glyphpos === 'left') {
            $button.prepend(glyphicon);
          } else {
            $button.append(glyphicon);
          }
        }

        // Append Custom Button to Button Container
        $modalFooter.append($button);
      }
    }

    let okButtonClass = 'btn-info';
    if (modalOptions.usePrimaryBtnStyle !== undefined && modalOptions.usePrimaryBtnStyle) {
      okButtonClass = 'btn-primary';
    }
    // Ok/Cancel Buttons in Footer (Alert and Confirm Modals)
    if (modalOptions.ok !== undefined && modalOptions.ok) {
      $(buttonTpl)
        .addClass(`btn-ok ${okButtonClass} pull-right`)
        .html(modalOptions.ok)
        .css({ display: 'inline-block' })
        .appendTo($modalFooter)
        .on('click.PopupModal', handleOk);
    }
    if (modalOptions.cancel !== undefined && modalOptions.cancel) {
      $(buttonTpl)
        .addClass('btn-cancel btn-default pull-right')
        .html(modalOptions.cancel)
        .css({ display: 'inline-block' })
        .appendTo($modalFooter)
        .on('click.PopupModal', handleCancel);
    }

    // Next/Previous Buttons in Footer (Step Modal)
    if (hasWizardLayout()) {
      // Next Button
      $(buttonTpl)
        .addClass('btn-next-step btn-default pull-right')
        .html(modalOptions.wizard.labels.next)
        .css({ display: 'inline-block' })
        .prop('disabled', true)
        .appendTo($modalFooter)
        .on('click.PopupModal', handleStepBtn(1));

      // Previous Button
      $(buttonTpl)
        .addClass('btn-previous-step btn-default pull-left')
        .html(modalOptions.wizard.labels.previous)
        .css({ display: 'inline-block' })
        .prop('disabled', true)
        .appendTo($modalFooter)
        .on('click.PopupModal', handleStepBtn(-1));
    }

    // Content in Footer
    if (_.isString(modalOptions.footer) && modalOptions.footer.length) {
      $modalFooter.append($('<p>').html(modalOptions.footer));
    }
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateSettings() {
    // Modal Accessibility (ARIA)
    const ariaLabel = $modal.attr('aria-labelledby');
    if (!ariaLabel || !ariaLabel.length) {
      $modal.attr({ 'aria-labelledby': `ariaLabel-${modalId}` });
      if ($modalHeaderText.length) {
        $modalHeaderText.attr('id', `ariaLabel-${modalId}`);
      } else {
        $modalHeader.attr('id', `ariaLabel-${modalId}`);
      }
    }

    // Modal Description (for ARIA)
    if (modalOptions.description !== undefined && modalOptions.description.length) {
      $modal.attr({ 'aria-describedby': modalOptions.description });
    }

    // Padding
    if ($modalContent.length && modalOptions.padding !== undefined && modalOptions.padding > 0) {
      $modalContent.css({ padding: modalOptions.padding });
    }

    // Width
    if (modalOptions.width !== undefined && modalOptions.width > 0) {
      $modal.find('.modal-dialog').css({ width: modalOptions.width });
    }

    // Height
    if (modalOptions.height !== undefined && modalOptions.height > 0) {
      $modalContent.css({ height: 'auto', 'max-height': modalOptions.height });
    } else {
      $modalContent.css({ height: 'auto', 'max-height': 'none' });
    }

    // Alignment
    if ($modalContent.length && modalOptions.align !== undefined && modalOptions.align.length) {
      $modalContent.css({ 'text-align': modalOptions.align });
    }

    // Use streams 1.5 styling if option is enabled
    if (modalOptions.usePrimaryBtnStyle !== undefined && modalOptions.usePrimaryBtnStyle) {
      $modalContent.css({ 'background-color': '#f9f9f9', 'border-top': 'none' });
      $modalFooter.css({ 'border-top': 'none' });
    }
  }

  /**
   *
   *
   * @private
   * @returns {string|boolean} If the Content comes from AJAX then the URL is returned, otherwise False
   */
  function updateContent() {
    let $iframe = null;
    let $domContent = null;
    let remoteContentUrl = false;

    // Hide Loading
    updateLoading(false);

    // Content URL Method
    if (!_.isString(modalOptions.method)) {
      modalOptions.method = 'text'; // Default to Text-Content Method
    }

    // Are we using an Existing Template from the Page?
    if (modalOptions.modal !== undefined && modalOptions.modal !== false) {
      // Modal Container
      $domContent = $(modalOptions.modal);
      if ($domContent.length) {
        // Get Modal Content
        modalOptions.content = $domContent.clone();
      }
    }

    // Prepare Content
    if ($modalContent.length && modalOptions.content !== undefined && modalOptions.content.length) {
      // Check for Content as Array and Flatten to String
      if (_.isArray(modalOptions.content)) {
        modalOptions.content = _.flatten(modalOptions.content).toString();
      }

      // Content as String (Text, HTML, URL)
      if (_.isString(modalOptions.content)) {
        // Content from URL (AJAX or IFrame)
        if (modalOptions.method !== 'text') {
          // Load from AJAX
          if (modalOptions.method === 'ajax') {
            remoteContentUrl = modalOptions.content;
            updateLoading(true);
          }
          // Load from IFrame
          else {
            // Update Height of Content
            if (modalOptions.height !== undefined && modalOptions.height > 0) {
              $modalContent.css({ height: modalOptions.height });
            }

            // Insert Iframe
            $iframe = $(
              '<iframe src="about:blank" frameborder="0" style="width:100%;height:99%">Your device does not support iframes.</iframe>',
            );
            $modalContent.html($iframe);
            $iframe.attr('src', modalOptions.content);
          }
        }
        // Content from HTML or Text
        else {
          $modalContent.html(modalOptions.content);
        }
      }
      // Content as Element
      else {
        $modalContent.append(modalOptions.content);
      }
    }

    return remoteContentUrl;
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateLoading(loadingState) {
    isLoading = loadingState;

    if (isLoading) {
      // Update Loading Text
      if (modalOptions.language.loadingText.length) {
        $modalLoading
          .find('.loading-text')
          .text(modalOptions.language.loadingText)
          .css({ display: 'block' });
      } else {
        $modalLoading.find('.loading-text').css({ display: 'none' });
      }
      if (modalOptions.language.loadingSubtext.length) {
        $modalLoading
          .find('.loading-subtext')
          .text(modalOptions.language.loadingSubtext)
          .css({ display: 'block' });
      } else {
        $modalLoading.find('.loading-subtext').css({ display: 'none' });
      }

      // Show Loading Image
      if (modalOptions.ajaxSpinner !== undefined && modalOptions.ajaxSpinner) {
        $modalLoading.css({ display: 'block' });
      }

      // Hide Footer
      $modalFooter.css({ opacity: 0, visibility: 'hidden' });

      // Hide Header Nav (Filters or Wizard)
      $modalHeaderTabs.css({ display: 'none' });
    } else {
      // Hide Loading Image
      $modalLoading.css({ display: 'none' });
    }

    // Prevent Closing Modal during Load
    updateCloseIcon();
    updateBootstrapModalOptions();
  }

  /**
   *
   *
   * @private
   * @returns {undefined}
   */
  function updateAfterContentRendered() {
    // Update Close Icon in Header
    updateCloseIcon();

    // Update Filters (if exists)
    updateFilters();

    // Update Wizard Steps (if exists)
    updateWizardSteps();

    // On Render Callback
    if (_.isFunction(modalOptions.onContentRender)) {
      modalOptions.onContentRender();
    }
  }

  /**
   *
   * @private
   * @returns {undefined}
   */
  function updateCloseIcon() {
    const header = hasHeader();
    const footer = hasFooter();

    // Attach Event to Close Icon
    if (isClosable && !isLoading) {
      if (header) {
        $closeIcon.css({ display: '' }).on('click.PopupModal', handleClose);
      }

      // If no Header or Footer, but Modal is Closable, add an Alternate Close Icon to Modal
      if (
        !header &&
        !footer &&
        !$modal.find('.modal-content #modal-v2-fallback-close').length // Ensure we don't add multiple buttons
      ) {
        $altCloseIcon = $(closeIconTpl).appendTo($modal.find('.modal-content'));
        $altCloseIcon
          .addClass('alt-close-icon')
          .css({ display: '' })
          .on('click.PopupModal', handleClose);
      }
    } else if (header) {
      $closeIcon.css({ display: 'none' }).off('click.PopupModal');
    }
  }

  function updateBootstrapModalOptions() {
    if ($modal.data('bs.modal') === undefined || $modal.data('bs.modal').options === undefined) {
      return;
    }

    $modal.data('bs.modal').options.keyboard = isClosable && !isLoading;
    $modal.data('bs.modal').options.backdrop = (isClosable && !isLoading) || 'static';
  }

  /**
   * Reload the content for the PopupModal
   *
   * @private
   * @param
   * @returns {undefined}
   */
  function reload(content, options) {
    if (PopupModal2.PreviousOptions === undefined) {
      return;
    }

    // Dont merge buttons, but replace instead
    if (modalOptions.buttons !== undefined) {
      modalOptions.buttons = []; // clear existing buttons
    }

    // Copy over previous options and update content
    modalOptions = $.extend(true, {}, PopupModal2.PreviousOptions, options);
    if (content !== undefined && content.length) {
      modalOptions.content = content;
    }

    // Store New Options
    PopupModal2.PreviousOptions = modalOptions;

    checkClosable();

    // Clear old content
    $modalContent.empty();

    // Update Modal
    updateHeader();
    updateFooter();
    updateSettings();

    // Fetch new content
    const remoteContentUrl = updateContent();
    if (remoteContentUrl && remoteContentUrl.length) {
      /* eslint-disable sort-keys */
      Q.invoke($, 'ajax', { url: remoteContentUrl, cache: false }).then(loaded).fail(failedLoad);
      /* eslint-enable sort-keys */
    } else {
      updateAfterContentRendered();
    }
  }

  /**
   *
   * @private
   * @param {string} activeKey
   * @returns {undefined}
   */
  function toggleActiveFilter(activeKey) {
    const $ul = $modalHeaderTabs.find('ul.nav');

    // Mark Filter as Active
    $ul.find('[data-filter-key]').removeClass('active');
    $ul.find(`[data-filter-key="${activeKey}"]`).addClass('active');

    // Display only Active Filter Content
    $modalContent.find('[role="tabpanel"]').css({ display: 'none' });
    $modalContent.find(`.${activeKey}[role="tabpanel"]`).css({ display: 'block' });
  }

  /**
   *
   * @private
   * @returns {undefined}
   */
  function toggleFinishButton(asFinishBtn) {
    // Update Class and Label on Next Button
    if (asFinishBtn) {
      $modalFooter
        .find('.btn-next-step')
        .addClass('as-finish-btn')
        .html(modalOptions.wizard.labels.finish);
    } else {
      $modalFooter
        .find('.btn-next-step')
        .removeClass('as-finish-btn')
        .html(modalOptions.wizard.labels.next);
    }
  }

  /**
   *
   * @private
   * @param {number} stepIndex
   * @returns {undefined}
   */
  function moveToStep(stepIndex) {
    const stepData = modalOptions.wizard.steps[stepIndex];
    let stepKey;
    let isDisabled;
    let isLastStep = false;

    // Switch Tabs
    toggleActiveFilter(stepData.key);

    // Track Current Step
    wizard.currentStep = stepIndex;
    isLastStep = wizard.currentStep === wizard.totalSteps - 1;

    // First Step has no Previous Step
    $modalFooter.find('.btn-previous-step').prop('disabled', wizard.currentStep === 0);

    // Last Step has no Next Step, but instead has a Finish Button
    if (isLastStep) {
      $modalFooter.find('.btn-next-step').prop('disabled', true);
    } else {
      // Next Step must be Enabled
      stepKey = modalOptions.wizard.steps[wizard.currentStep + 1].key;
      isDisabled = $modalHeader.find(`[data-filter-key="${stepKey}"] > a`).hasClass('disabled');
      $modalFooter.find('.btn-next-step').prop('disabled', isDisabled);
    }

    // Update Label on Next Button
    toggleFinishButton(isLastStep);

    // Call Step Function for Custom Logic
    if (_.isFunction(stepData.onStep)) {
      onNextEventLoop(stepData.onStep);
    }
  }

  /**
   *
   * @private
   * @param {string} filterKey
   * @returns {Function} An event handler with closure around the "filterKey" param
   */
  function handleFilterTab(filterKey) {
    return function PopupModal_handleFilterTab() {
      // Toggle the Active Filter
      toggleActiveFilter(filterKey);
    };
  }

  /**
   *
   * @private
   * @param {number} stepIndex
   * @returns {Function} An event handler with closure around the "filterKey" param
   */
  function handleStepTab(stepIndex) {
    return function PopupModal_handleStepTab(e) {
      const $link = $(e.currentTarget);
      if ($link.hasClass('disabled')) {
        return false;
      }

      // Move to the Selected Step
      moveToStep(stepIndex);
      return false;
    };
  }

  /**
   * Handles the Click Event on Step Buttons
   *
   * @private
   * @param {number} direction
   * @returns {Function} A Closure around the "direction" param; Event Handler for the Step Button
   */
  function handleStepBtn(direction) {
    return function PopupModal_stepBtnHandler(e) {
      const $btn = $(e.currentTarget);
      if (direction > 0) {
        if ($btn.hasClass('as-finish-btn')) {
          handleOk(); // Finish Button Clicked; Hide Modal and Resolve Promise
        } else {
          nextStep();
        }
      } else {
        previousStep();
      }
    };
  }

  /**
   * Handles the Click Event on the OK Button
   *   - Hides the Modal and Resolves the Promise
   *
   * @private
   * @returns {undefined}
   */
  function handleOk() {
    // Hide Modal
    hide(true);
  }

  /**
   * Handles the Click Event on the Cancel Button
   *   - Hides the Modal and Rejects the Promise
   *
   * @private
   * @returns {undefined}
   */
  function handleCancel() {
    // Hide Modal
    hide(false);
  }

  /**
   * Handles the Click Event on the Close Icon
   *   - Hides the Modal and Rejects the Promise
   *
   * @private
   * @returns {undefined}
   */
  function handleClose() {
    // Hide Modal
    if (isClosable) {
      hide(false);
    }
  }

  /**
   * Handles the Click Event on Custom Buttons
   *   - If buttonData has a 'promise' property, the Custom Button will Close the Modal
   *   and either Resolve or Reject the Promise based on the property value.
   *
   * @private
   * @param {object} buttonData
   * @returns {Function} A Closure around the "buttonData" param; Event Handler for the Custom Button
   */
  function handleCustom(buttonData) {
    return function PopupModal_customButtonHandler(e) {
      // Call Button Handler
      if (_.isFunction(buttonData.handler)) {
        buttonData.handler.apply(this, [e, $modal]);
      }

      // For ajax requests that return JSON, the function will be returned as a string
      if (_.isString(buttonData.handler) && window[buttonData.handler] !== undefined) {
        window[buttonData.handler].apply(this, [e, $modal]);
      }
      if (_.isString(buttonData.handler) && AppGlobals[buttonData.handler] !== undefined) {
        AppGlobals[buttonData.handler].apply(this, [e, $modal]);
      }

      // Resolve/Reject Promise?
      if (_.isString(buttonData.promise)) {
        hide(buttonData.promise === 'resolve');
      }
    };
  }

  /**
   * Shakes the Popup Modal to draw users' attention to the Non-Closable Popup Modal
   *
   * @private
   * @returns {undefined}
   */
  function shake() {
    const originalLeft = _.parseInt($modal.css('margin-left'), 10);
    for (let i = 0; i < 3; i++) {
      $modal
        .animate({ 'margin-left': originalLeft - 10 }, 33)
        .animate({ 'margin-left': originalLeft + 10 }, 66)
        .animate({ 'margin-left': originalLeft }, 33);
    }
  }

  /**
   *
   *
   * @private
   * @returns {boolean} True if the current Modal requires a Header; otherwise False
   */
  function hasHeader() {
    const hasHeaderText = modalOptions.header !== undefined && _.isString(modalOptions.header);
    const hasHeaderOptions =
      modalOptions.header !== undefined &&
      _.isPlainObject(modalOptions.header) &&
      _.size(modalOptions.header);
    return !!($modalHeader.length && (hasHeaderText || hasHeaderOptions));
  }

  /**
   *
   *
   * @private
   * @returns {boolean} True if the current Modal requires a Footer; otherwise False
   */
  function hasFooter() {
    const hasFooterText = modalOptions.footer !== undefined && modalOptions.footer !== false;
    const hasButtons = modalOptions.buttons !== undefined && _.isArray(modalOptions.buttons);
    const hasOkButton =
      modalOptions.ok !== undefined && _.isString(modalOptions.ok) && modalOptions.ok.length;
    const hasCancelButton =
      modalOptions.cancel !== undefined &&
      _.isString(modalOptions.cancel) &&
      modalOptions.cancel.length;
    return !!(
      $modalFooter.length &&
      (hasFooterText || hasButtons || hasOkButton || hasCancelButton || hasWizardLayout())
    );
  }

  /**
   *
   *
   * @private
   * @returns {boolean} True if the current Modal requires a Filter Nav; otherwise False
   */
  function hasFilterLayout() {
    const hasFilterOptions =
      modalOptions.filters !== undefined &&
      _.isPlainObject(modalOptions.filters) &&
      _.size(modalOptions.filters);
    return hasFilterOptions;
  }

  /**
   *
   *
   * @private
   * @returns {boolean} True if the current Modal requires a Wizard Nav; otherwise False
   */
  function hasWizardLayout() {
    const hasWizard = modalOptions.wizard !== undefined && _.isPlainObject(modalOptions.wizard);
    const hasSteps =
      modalOptions.wizard.steps !== undefined &&
      _.isArray(modalOptions.wizard.steps) &&
      modalOptions.wizard.steps.length;
    return !!(hasWizard && hasSteps);
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Private Methods Exposed to Public Interface
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  /**
   * Gets the Modal Element used by Bootstrap (useful for attaching Bootstrap-Modal events)
   *
   * @private
   * @exposed
   * @returns {object} A reference to the jQuery Modal Element
   */
  function getModal() {
    return $modal;
  }

  /**
   * Display a Popup Modal with Custom Content (HTML, IFrame, URL)
   *
   * @private
   * @exposed
   * @param {mixed} content The Content to Populate the Modal with; if the Param is a String URL, the content will be loaded via AJAX
   * @param {object} [options] Options for customizing the Popup Modal
   * @returns {Promise.<mixed>} - The Resolve Value if the Modal was Resolved when Closed, otherwise the Rejection Value if the Modal was Rejected when Closed
   */
  function showContent(content, options) {
    const tmpOptions = {
      content,
    };

    // Merge Custom Options with Default Options
    modalOptions = $.extend(true, {}, PopupModal2.defaultOptions, tmpOptions, options);

    // Forced Settings
    modalOptions.type = 'content';

    // Show the Modal
    return show();
  }

  /**
   * Display a Popup Modal as a Browser Alert
   *
   * @private
   * @exposed
   * @param {string} message The Message to Populate the Modal with
   * @param {object} [options] Options for customizing the Popup Modal
   * @returns {Promise.<mixed>} - The Resolve Value if the Modal was Resolved when Closed, otherwise the Rejection Value if the Modal was Rejected when Closed
   */
  function showAlert(message, options) {
    // Default Options for the Alert Popup Modal
    /* eslint-disable sort-keys */
    const tmpOptions = {
      header: 'Alert!',
      content: $('<p>').html(message),
      width: 560,
      padding: 15,
      align: 'center',
    };
    /* eslint-enable sort-keys */

    // Merge Custom Options with Default Options
    modalOptions = $.extend(true, {}, PopupModal2.defaultOptions, tmpOptions, options);

    // Forced Settings
    modalOptions.type = 'alert';
    modalOptions.cancel = false;
    modalOptions.closable = false;
    modalOptions.autoResize = true;
    modalOptions.useFade = false;
    if (!_.isString(modalOptions.ok)) {
      modalOptions.ok = 'OK';
    }

    // Show the Modal
    return show();
  }

  /**
   * Display a Popup Modal as a Browser Confirm Prompt
   *
   * @private
   * @exposed
   * @param {string} message The Message to Populate the Modal with
   * @param {object} [options] Options for customizing the Popup Modal
   * @returns {Promise.<mixed>} - The Resolve Value if the Modal was Resolved when Closed, otherwise the Rejection Value if the Modal was Rejected when Closed
   */
  function showConfirm(message, options) {
    // Default Options for the Confirm Popup Modal
    /* eslint-disable sort-keys */
    const tmpOptions = {
      header: 'Are you sure?',
      content: $('<p>').html(message),
      width: 560,
      padding: 15,
      closable: false,
    };
    /* eslint-enable sort-keys */

    // Merge Custom Options with Default Options
    modalOptions = $.extend(true, {}, PopupModal2.defaultOptions, tmpOptions, options);

    // Forced Settings
    modalOptions.type = 'confirm';
    modalOptions.autoResize = true;
    if (!_.isString(modalOptions.ok)) {
      modalOptions.ok = 'OK';
    }
    if (!_.isString(modalOptions.cancel)) {
      modalOptions.cancel = 'Cancel';
    }

    // Show the Modal
    return show();
  }

  /**
   *
   * @private
   * @exposed
   * @returns {boolean} True if the Modal moved to the Next Step
   */
  function nextStep() {
    const newStep = wizard.currentStep + 1;
    if (newStep >= wizard.totalSteps) {
      return false;
    }
    const stepKey = modalOptions.wizard.steps[newStep].key;
    if ($modalHeader.find(`[data-filter-key="${stepKey}"] > a`).hasClass('disabled')) {
      return false;
    }
    moveToStep(newStep);
    return true;
  }

  /**
   *
   * @private
   * @exposed
   * @returns {boolean} True if the Modal moved to the Previous Step
   */
  function previousStep() {
    const newStep = wizard.currentStep - 1;
    if (newStep < 0) {
      return false;
    }
    const stepKey = modalOptions.wizard.steps[newStep].key;
    if ($modalHeader.find(`[data-filter-key="${stepKey}"] > a`).hasClass('disabled')) {
      return false;
    }
    moveToStep(newStep);
    return true;
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function enableNextStep() {
    const newStep = wizard.currentStep + 1;
    if (newStep > wizard.totalSteps - 1) {
      return enableFinishStep();
    }
    const stepKey = modalOptions.wizard.steps[newStep].key;

    // Enable Tab Link
    $modalHeader.find(`[data-filter-key="${stepKey}"] > a`).removeClass('disabled');

    // Enable Next Button in Footer
    $modalFooter.find('.btn-next-step').prop('disabled', false);
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function disableNextStep() {
    const newStep = wizard.currentStep + 1;
    if (newStep > wizard.totalSteps - 1) {
      return;
    }
    const stepKey = modalOptions.wizard.steps[newStep].key;

    // Enable Tab Link
    $modalHeader.find(`[data-filter-key="${stepKey}"] > a`).addClass('disabled');

    // Enable Next Button in Footer
    $modalFooter.find('.btn-next-step').prop('disabled', true);
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function enablePreviousStep() {
    const newStep = wizard.currentStep - 1;
    if (newStep < 0) {
      return;
    }
    const stepKey = modalOptions.wizard.steps[newStep].key;

    // Enable Tab Link
    $modalHeader.find(`[data-filter-key="${stepKey}"] > a`).removeClass('disabled');

    // Enable Next Button in Footer
    $modalFooter.find('.btn-previous-step').prop('disabled', false);
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function disablePreviousStep() {
    const newStep = wizard.currentStep - 1;
    if (newStep < 0) {
      return;
    }
    const stepKey = modalOptions.wizard.steps[newStep].key;

    // Enable Tab Link
    $modalHeader.find(`[data-filter-key="${stepKey}"] > a`).addClass('disabled');

    // Enable Next Button in Footer
    $modalFooter.find('.btn-previous-step').prop('disabled', true);
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function enableFinishStep() {
    // Enable finish Button in Footer
    $modalFooter.find('.btn-next-step').prop('disabled', false);
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function disableFinishStep() {
    // Disable finish Button in Footer
    $modalFooter.find('.btn-next-step').prop('disabled', true);
  }

  /**
   *
   * @private
   * @exposed
   * @returns {undefined}
   */
  function updateLoadingText(text, subtext) {
    modalOptions.language.loadingText = text;
    modalOptions.language.loadingSubtext = subtext;
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Expose Singleton Methods
  return exports;
})(); // End of PopupModal Class

// PopupModal Default Options
/* eslint-disable sort-keys */
PopupModal2.defaultOptions = {
  type: 'content',
  method: 'text',
  modal: false,
  header: false,
  footer: false,
  content: '',
  closable: true,
  autoResize: false,
  ajaxSpinner: true,
  useFade: true,
  width: 0,
  height: 0,
  padding: 0,
  align: 'left',
  usePrimaryBtnStyle: false,
  buttons: [],
  ok: false,
  cancel: false,
  filters: {},
  wizard: { labels: { next: 'Next', previous: 'Previous', finish: 'Finish' } },
  language: { loadingText: 'Loading', loadingSubtext: 'Please wait...' },
  onShown: null,
  onContentRender: null,
  onAjaxLoad() {
    return true;
  }, // return true to show footer after load, false otherwise
  onAjaxFail() {
    return true;
  }, // return true to show footer after load, false otherwise
  className: '',
};
/* eslint-enable sort-keys */

// Static variable for previous options
PopupModal2.PreviousOptions = {};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Monkey-Patching the "alert" method of the browser
//  (allows secondary alerts to come through as standard browser alerts, this way PopupModals can still fire alerts)
const browserAlert = window.alert;
window.alert = function PopupModal_window_alert() {
  const args = [].splice.call(arguments, 0);
  const $modal = PopupModal2.getModal();
  if ($modal && $modal.length) {
    browserAlert.apply(this, args);
  } else {
    PopupModal2.alert.apply(this, args);
  }
};

export default PopupModal2;
